import { useMutation, useQuery } from 'react-query';
import { getAuth } from '@firebase/auth';
import { and, collection, getDocs, limit, orderBy, query, where } from 'firebase/firestore';
import { firestore } from '../../../utils/firebase';
import { STATUS_SOLICITARE_RETUR } from '../../../pages/retururi/utils';

const { REACT_APP_API_URL: API_URL } = process.env;

const fetchInsurers = async () => {
	const token = await getAuth().currentUser.getIdToken();

	const res = await fetch(`${API_URL}/insurers`, {
		headers: { authorization: `Bearer ${token}` },
	});
	const data = await res.json();

	if (data.error) throw new Error(`[fetchInsurers] ${data.error}`);

	return data;
};

const fetchInsurerData = async insurerId => {
	const token = await getAuth().currentUser.getIdToken();

	const res = await fetch(`${API_URL}/insurers/${insurerId}`, {
		headers: { authorization: `Bearer ${token}` },
	});
	const data = await res.json();

	if (data.error) throw new Error(`[fetchInsurerData] ${data.error}`);

	return data;
};

const fetchStatuses = async orderId => {
	const token = await getAuth().currentUser.getIdToken();

	const res = await fetch(`${API_URL}/file-status/${orderId}`, {
		headers: { authorization: `Bearer ${token}` },
	});
	const data = await res.json();

	if (data.error) throw new Error(`[fetchStatuses] ${data.error}`);

	return data;
};

export const useFetchInsurers = (options = {}) => {
	return useQuery(['insurers'], () => fetchInsurers(), {
		staleTime: 5 * 60 * 1000,
		retry: false,
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		...options,
	});
};

export const useFetchInsurerData = (insurerId, options = {}) => {
	return useQuery(['insurers', insurerId], () => fetchInsurerData(insurerId), {
		staleTime: 5 * 60 * 1000,
		retry: false,
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		...options,
	});
};

export const useFetchStatuses = (orderId, options = {}) => {
	return useQuery(['statuses', orderId], () => fetchStatuses(orderId), {
		// staleTime: 5 * 60 * 1000,
		retry: false,
		// refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		...options,
	});
};

const fetchScanariDosar = async orderId => {
	const token = await getAuth().currentUser.getIdToken();

	const res = await fetch(`${API_URL}/documents/${orderId}?type=scanari-dosar`, {
		headers: { authorization: `Bearer ${token}` },
	});
	const data = await res.json();

	if (data.error) throw new Error(`[${fetchScanariDosar.name}] ${data.error}`);

	return data;
};

export const useFetchScanariDosar = (orderId, options = {}) => {
	return useQuery(['scanari-dosar', orderId], () => fetchScanariDosar(orderId), {
		staleTime: 1 * 60 * 1000,
		retry: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		...options,
	});
};

// Define priority values for each status
const getPriority = status => {
	if (status === STATUS_SOLICITARE_RETUR.necesitaReturFurnizor) return 1;
	if (status === STATUS_SOLICITARE_RETUR.necesitaAprobare) return 2;
	return 3; // Any other status
};

const fetchSolicitariReturPiese = async (filters, includeSolicitariFinalizate) => {
	const collectionRef = collection(firestore, 'solicitari-retur-piese');

	console.log('fetchSolicitariReturPiese filters', filters, includeSolicitariFinalizate);
	const filtersArray = Array.from(filters.values()).filter(filter => filter.value.length > 0);
	const queryConstaints = [];
	let statusQuery = [
		where('status', 'in', [
			STATUS_SOLICITARE_RETUR.inLucru,
			STATUS_SOLICITARE_RETUR.necesitaAprobare,
			STATUS_SOLICITARE_RETUR.necesitaReturFurnizor,
		]),
	];

	for (const filter of filtersArray) {
		if (filter.filterType === 'text') {
			const value = filter.valueType === 'number' ? Number(filter.value) : filter.value;
			queryConstaints.push(where(filter.name, '==', value));

			if (['devizId', 'nrAuto'].includes(filter.name) && includeSolicitariFinalizate) {
				statusQuery = [];
			}

			continue;
		}

		queryConstaints.push(
			where(
				filter.name,
				'in',
				filter.value.map(v => v.value)
			)
		);
	}

	const q = query(collectionRef, and(...statusQuery, ...queryConstaints), orderBy('createdAt'), limit(50));

	const snapshot = await getDocs(q);

	const result = [];
	for (const doc of snapshot.docs) {
		const solicitare = doc.data();
		solicitare.id = doc.id;
		result.push(solicitare);
	}

	// Sort results in priority order: 'Necesita retur furnizor' first, then 'Necesita aprobare', then others
	// Within each status group, sort by createdAt in descending order
	return result.sort((a, b) => {
		const priorityA = getPriority(a.status);
		const priorityB = getPriority(b.status);

		// First sort by priority
		if (priorityA !== priorityB) {
			return priorityA - priorityB;
		}

		// If same priority, sort by createdAt in descending order
		return b.createdAt - a.createdAt;
	});
};

export const useFetchSolicitariReturPiese = (filters, includeSolicitariFinalizate, options = {}) => {
	const serializedFilters = Object.fromEntries(filters);

	return useQuery(
		['solicitari-retur-piese', serializedFilters, includeSolicitariFinalizate],
		() => fetchSolicitariReturPiese(filters, includeSolicitariFinalizate),
		{
			staleTime: 1 * 60 * 1000,
			retry: false,
			...options,
		}
	);
};

const respingeReturPiesa = async (devizId, solicitareId, reperId, reperName, docId, motivRespingere) => {
	const token = await getAuth().currentUser.getIdToken();

	const res = await fetch(`${API_URL}/deviz/${devizId}/solicitare/retur-piese/respinge`, {
		headers: { authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
		method: 'POST',
		body: JSON.stringify({
			solicitareId,
			reperId,
			reperName,
			docId,
			motivRespingere,
		}),
	});

	if (!res.ok) {
		const error = await res.json();
		throw new Error(error.error);
	}

	return await res.json();
};

export const useRespingeReturPiesa = () => {
	return useMutation(({ devizId, solicitareId, reperId, reperName, docId, motivRespingere }) =>
		respingeReturPiesa(devizId, solicitareId, reperId, reperName, docId, motivRespingere)
	);
};

const aprobaReturPiesa = async (devizId, solicitareId, reperId, reperName, docId, qtyAprobata) => {
	const token = await getAuth().currentUser.getIdToken();

	const res = await fetch(`${API_URL}/deviz/${devizId}/solicitare/retur-piese/aproba`, {
		headers: { authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
		method: 'POST',
		body: JSON.stringify({
			solicitareId,
			reperId,
			reperName,
			docId,
			qtyAprobata,
		}),
	});

	if (!res.ok) {
		const error = await res.json();
		throw new Error(error.error);
	}

	return await res.json();
};

export const useAprobaReturPiesa = () => {
	return useMutation(({ devizId, solicitareId, reperId, reperName, docId, qtyAprobata }) =>
		aprobaReturPiesa(devizId, solicitareId, reperId, reperName, docId, qtyAprobata)
	);
};

const returneazaLaFurnizor = async (devizId, solicitareId, reperId, reperName, docId, crtAviz) => {
	const token = await getAuth().currentUser.getIdToken();

	const res = await fetch(`${API_URL}/deviz/${devizId}/solicitare/retur-piese/retur-la-furnizor`, {
		headers: { authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
		method: 'POST',
		body: JSON.stringify({
			solicitareId,
			reperId,
			reperName,
			docId,
			crtAviz,
		}),
	});

	if (!res.ok) {
		const error = await res.json();
		throw new Error(error.error);
	}

	return await res.json();
};

export const useReturneazaLaFurnizor = () => {
	return useMutation(({ devizId, solicitareId, reperId, reperName, docId, crtAviz }) =>
		returneazaLaFurnizor(devizId, solicitareId, reperId, reperName, docId, crtAviz)
	);
};

const intoarceInDepozit = async (devizId, solicitareId, reperId, reperName, docId, motivNereturnare) => {
	const token = await getAuth().currentUser.getIdToken();

	const res = await fetch(`${API_URL}/deviz/${devizId}/solicitare/retur-piese/fail-retur`, {
		headers: { authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
		method: 'POST',
		body: JSON.stringify({
			solicitareId,
			reperId,
			reperName,
			docId,
			motivNereturnare,
		}),
	});

	if (!res.ok) {
		const error = await res.json();
		throw new Error(error.error);
	}

	return await res.json();
};

export const useIntoarceInDepozit = () => {
	return useMutation(({ devizId, solicitareId, reperId, reperName, docId, motivNereturnare }) =>
		intoarceInDepozit(devizId, solicitareId, reperId, reperName, docId, motivNereturnare)
	);
};
