export const STATUS_SOLICITARE_RETUR = Object.freeze({
	inLucru: 'In lucru',
	necesitaAprobare: 'Necesita aprobare',
	necesitaReturFurnizor: 'Necesita retur furnizor',
	finalizata: 'Finalizata',
});

export const STATUS_REPER_DE_RETURNAT = Object.freeze({
	necesitaAprobare: 'Necesita aprobare',
	aprobat: 'Aprobat',
	respins: 'Respins',
	deReturnat: 'De returnat',
	returnat: 'Returnat',
});

export const STATUS_REPER_FURNIZOR = Object.freeze({
	deReturnat: 'De returnat',
	returnat: 'Returnat',
	failed: 'Inapoi in stoc',
});

export const statusReperColors = {
	[STATUS_REPER_DE_RETURNAT.necesitaAprobare]: 'yellow',
	[STATUS_REPER_DE_RETURNAT.aprobat]: 'green',
	[STATUS_REPER_DE_RETURNAT.respins]: 'red',
	[STATUS_REPER_DE_RETURNAT.deReturnat]: 'green',
	[STATUS_REPER_DE_RETURNAT.returnat]: 'gray',
};

export const statusComandaFurnizorColors = {
	[STATUS_REPER_FURNIZOR.deReturnat]: 'yellow',
	[STATUS_REPER_FURNIZOR.returnat]: 'green',
	[STATUS_REPER_FURNIZOR.failed]: 'red',
};
