import { useForm } from 'react-hook-form';
import { Link as RRLink, useNavigate } from 'react-router-dom';
import {
	FormErrorMessage,
	FormControl,
	Input,
	Button,
	Flex,
	InputGroup,
	InputRightElement,
	Center,
	Link,
	Text,
} from '@chakra-ui/react';
import { FaLongArrowAltRight } from 'react-icons/fa';
import AdminButton from '../components/home/admin-button';
import { useAuthState } from '../utils/firebase';
import { canSeeReports, canSeeRetururi } from '../utils/permissions';

export default function SearchForm() {
	const navigate = useNavigate();
	const { user } = useAuthState();

	const {
		handleSubmit,
		register,
		formState: { errors, isSubmitting },
	} = useForm();

	function onSubmit(values) {
		navigate(`/${values.orderId}`);
	}

	return (
		<Center h={'calc(100vh - 64px)'}>
			<Flex direction={'column'} minW={'400px'}>
				<form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
					<FormControl isInvalid={errors.orderId}>
						<InputGroup>
							<Input
								id="orderId"
								type="number"
								size={'lg'}
								placeholder="Crt deviz"
								{...register('orderId', {
									required: 'Trebuie completat!',
								})}
							/>
							<InputRightElement
								width="90px"
								mt={1}
								children={
									<Button size={'lg'} isLoading={isSubmitting} type="submit">
										Cauta
									</Button>
								}
							/>
						</InputGroup>
						<FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
					</FormControl>
				</form>
				<br />
				{canSeeReports(user) && (
					<Link
						as={RRLink}
						to={'/rapoarte/comenzi-piese-har'}
						mt={2}
						w="100%"
						display="flex"
						alignItems="center"
						justifyContent="center"
						gap={2}
						_hover={{ color: 'blue.300', textDecoration: 'underline' }}
					>
						<Text>Rapoarte</Text>
						<FaLongArrowAltRight />
					</Link>
				)}
				{canSeeRetururi(user) && (
					<Link
						as={RRLink}
						to={'/retururi/solicitari'}
						mt={2}
						w="100%"
						display="flex"
						alignItems="center"
						justifyContent="center"
						gap={2}
						_hover={{ color: 'blue.300', textDecoration: 'underline' }}
					>
						<Text>Solicitari retururi</Text>
						<FaLongArrowAltRight />
					</Link>
				)}
				{user.level > 2 && <AdminButton />}
			</Flex>
		</Center>
	);
}
