import { Button, Flex } from '@chakra-ui/react';
import { Outlet, useNavigate, Navigate } from 'react-router-dom';
import { canSeeRetururi } from '../../utils/permissions';
import { useAuthState } from '../../utils/firebase';
import BackButton from '../../components/common/basic/back-button';

export const RetururiLayout = () => {
	const { user } = useAuthState();
	const navigate = useNavigate();

	const handleNavigate = route => {
		navigate(route);
	};

	const handleBack = () => {
		navigate('/');
	};

	if (!canSeeRetururi(user)) {
		return <Navigate to={'/'} />;
	}

	return (
		<Flex w={'100%'} h={'calc(100vh - 64px)'} borderTop={'1px'} borderColor={'gray.700'}>
			<Flex direction={'column'} w={'250px'} borderRight={'1px'} borderColor={'gray.700'}>
				<Button
					justifyContent={'flex-start'}
					variant={'ghost'}
					colorScheme={'blue'}
					mt={1}
					rounded={'none'}
					isFullWidth
					onClick={() => handleNavigate('/retururi/solicitari')}
				>
					Solicitari retururi
				</Button>
				<BackButton
					justifyContent={'flex-start'}
					rounded={'none'}
					size={'md'}
					colorScheme={'red'}
					onClick={handleBack}
				>
					Inapoi la aplicatie
				</BackButton>
			</Flex>
			<Flex flexGrow={1} direction={'column'} py={6} px={6} overflowY={'auto'} height={'100%'}>
				<Outlet />
			</Flex>
		</Flex>
	);
};
