export const canSeeReports = user =>
	user.department === 'admin' ||
	(user.department === 'altele' && user.level === 2) ||
	(user.department === 'caroserie' && user.level === 3);

export const canSeeRetururi = user =>
	user.department === 'admin' ||
	(user.department === 'altele' && user.level === 2) ||
	(user.department === 'caroserie' && user.level === 3);

export const isAdmin = user => user.department === 'admin';
