import {
	Badge,
	Box,
	Button,
	Checkbox,
	Collapse,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	IconButton,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Stack,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Textarea,
	Th,
	Thead,
	Tooltip,
	Tr,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import Select from 'react-select';
import {
	useAprobaReturPiesa,
	useFetchSolicitariReturPiese,
	useIntoarceInDepozit,
	useRespingeReturPiesa,
	useReturneazaLaFurnizor,
} from '../../state/server-side/queries/documents-status';
import { IoMdCheckmark, IoMdClose, IoMdRefresh, IoMdTime } from 'react-icons/io';
import { useQueryClient } from 'react-query';
import {
	STATUS_REPER_DE_RETURNAT,
	STATUS_REPER_FURNIZOR,
	STATUS_SOLICITARE_RETUR,
	statusComandaFurnizorColors,
	statusReperColors,
} from './utils';

const FILTERS_STRUCTURE = [
	{ name: 'brand', label: 'Brand', filterType: 'select', valueType: 'string', value: [] },
	{ name: 'tipDeviz', label: 'Tip deviz', filterType: 'select', valueType: 'string', value: [] },
	{
		name: 'devizId',
		label: 'Crt deviz',
		placeholder: 'ex: 337751',
		filterType: 'text',
		valueType: 'number',
		value: '',
	},
	{
		name: 'nrAuto',
		label: 'Nr. auto',
		placeholder: 'ex: B808ALG',
		filterType: 'text',
		valueType: 'string',
		value: '',
	},
];
const initialFilters = getInitialFilterValues(FILTERS_STRUCTURE);
const filterKeys = Array.from(initialFilters.keys());

export const SolicitariRetururiPage = () => {
	const queryClient = useQueryClient();
	const [filters, setFilters] = useState(initialFilters);
	const [activeFilters, setActiveFilters] = useState(initialFilters);
	const [includeSolicitariFinalizate, setIncludeSolicitariFinalizate] = useState(false);
	const { data, isFetching, isError, error } = useFetchSolicitariReturPiese(
		activeFilters,
		includeSolicitariFinalizate
	);

	const filterOptions = buildFilterOptions(data, filterKeys);

	const handleFilterChange = (value, filterName) => {
		// console.log('in handleFilterChange', { value, filterName });
		const newFilters = new Map(filters);
		const oldFilter = newFilters.get(filterName);
		newFilters.set(filterName, { ...oldFilter, value });
		setFilters(newFilters);
	};

	const handleSearch = includeFinalizate => {
		setActiveFilters(filters);
		setIncludeSolicitariFinalizate(includeFinalizate);
	};

	const handleRefreshList = async () => {
		await queryClient.invalidateQueries(['solicitari-retur-piese', filters]);
	};

	const handleResetFilters = async () => {
		setFilters(initialFilters);
		setActiveFilters(initialFilters);
		setIncludeSolicitariFinalizate(false);
	};

	if (isError) {
		return <Text>Error: {JSON.stringify(error)} \\\ Incearca un refresh la pagina.</Text>;
	}

	return (
		<Flex flexDirection={'column'} gap={4}>
			<Heading as={'h1'} size={'lg'}>
				Lista solicitari retururi
			</Heading>

			<FiltreSection
				filters={Array.from(filters.values())}
				filterOptions={filterOptions}
				isFetching={isFetching}
				onChange={handleFilterChange}
				onResetFilters={handleResetFilters}
				onRefreshList={handleRefreshList}
				onSearch={handleSearch} // Add this line
			/>

			<SolicitariList data={data} />
		</Flex>
	);
};

const SolicitariList = ({ data }) => {
	if (!data || data?.length === 0) return <Text>Nu exista solicitari de retur active sau cu filtrele aplicate.</Text>;

	return (
		<Flex flexDirection={'column'} gap={4}>
			{data.map(solicitare => (
				<Solicitare key={solicitare.id} solicitare={solicitare} />
			))}
		</Flex>
	);
};

const Solicitare = ({ solicitare }) => {
	const { isOpen, onToggle } = useDisclosure();
	const { isOpen: isOpenAudit, onToggle: onToggleAudit } = useDisclosure();

	return (
		<Box pos={'relative'}>
			<Flex p={4} border={'1px'} borderColor={'gray.700'} borderRadius={'md'} justifyContent={'space-between'}>
				<Flex gap={4} flexWrap={'wrap'}>
					<Flex flexDirection={'column'}>
						<SolicitareInfoTitle>Crt deviz</SolicitareInfoTitle>
						<Text>{solicitare.devizId}</Text>
					</Flex>
					<Flex flexDirection={'column'}>
						<SolicitareInfoTitle>Nr. auto</SolicitareInfoTitle>
						<Text>{solicitare.nrAuto}</Text>
					</Flex>
					<Flex flexDirection={'column'}>
						<SolicitareInfoTitle>Auto</SolicitareInfoTitle>
						<Text>
							{solicitare.brand} {solicitare.model}
						</Text>
					</Flex>
					<Flex flexDirection={'column'}>
						<SolicitareInfoTitle>Tip deviz</SolicitareInfoTitle>
						<Text>{solicitare.tipDeviz}</Text>
					</Flex>
					<Flex flexDirection={'column'}>
						<SolicitareInfoTitle>Status</SolicitareInfoTitle>
						<Badge p={1}>{solicitare.status}</Badge>
					</Flex>
					<Flex flexDirection={'column'}>
						<SolicitareInfoTitle>Solicitat la</SolicitareInfoTitle>
						<Text>{solicitare.createdAt.toDate().toLocaleString('ro-RO')}</Text>
					</Flex>
					<Flex flexDirection={'column'}>
						<SolicitareInfoTitle>Solicitant</SolicitareInfoTitle>
						<Text>{solicitare.createdBy.name}</Text>
					</Flex>
					<Flex flexDirection={'column'}>
						<SolicitareInfoTitle>Gestionar</SolicitareInfoTitle>
						<Text>{solicitare.gestionar?.name || <em>N/A</em>}</Text>
					</Flex>
				</Flex>
				<Flex gap={2}>
					{solicitare.audit.length > 0 && (
						<Button variant={'outline'} leftIcon={<IoMdTime />} alignSelf={'center'} onClick={onToggleAudit}>
							Istoric modificari
						</Button>
					)}
					<Button alignSelf={'center'} onClick={onToggle}>
						Vezi detalii
					</Button>
				</Flex>
			</Flex>
			<Collapse in={isOpen} unmountOnExit animateOpacity>
				<Flex
					flexDirection={'column'}
					p={4}
					border={'1px'}
					borderColor={'gray.700'}
					borderRadius={'md'}
					bg={'gray.900'}
				>
					<TableContainer>
						<Table size="sm" variant="simple">
							<Thead>
								<Tr>
									{['Crt reper', 'Denumire', 'Cantitate', 'Status', 'Status cmd furnizor', 'Actiuni'].map(
										header => (
											<Th key={header} color="gray.400" borderColor="gray.600" fontFamily={'monospace'}>
												{header}
											</Th>
										)
									)}
								</Tr>
							</Thead>
							<Tbody>
								{solicitare.repere.map((reper, idx) => {
									const colorScheme = statusReperColors[reper.status];
									const colorSchemeComandaFurnizor = statusComandaFurnizorColors[reper.statusComandaFurnizor];

									const showStatusComandaFurnizor =
										reper.status !== STATUS_REPER_DE_RETURNAT.respins && reper.areComandaFurnizor;

									return (
										<Tr key={idx} _hover={{ bg: 'gray.800' }}>
											<Td color="gray.100" borderColor="gray.600">
												{reper.reperId}
											</Td>
											<Td color="gray.100" borderColor="gray.600">
												{reper.reperName}
											</Td>
											<Td color="gray.100" borderColor="gray.600">
												<Flex flexDirection={'column'} gap={1}>
													<Flex gap={2}>
														<Text>Solicitata:</Text>
														<Text>{reper.qtySolicitata}</Text>
													</Flex>
													<Flex gap={2}>
														<Text>Aprobata:</Text>
														<Text>{reper.qtyAprobata ?? <em>N/A</em>}</Text>
													</Flex>
													<Flex gap={2}>
														<Text>Returnata:</Text>
														<Text>{reper.qtyReturnata ?? <em>N/A</em>}</Text>
													</Flex>
												</Flex>
											</Td>
											<Td borderColor="gray.600">
												{reper.status === 'Respins' ? (
													<Tooltip hasArrow label={`Motiv: ${reper.motivRespingere}`}>
														<Badge colorScheme={colorScheme} p={2}>
															{reper.status}
														</Badge>
													</Tooltip>
												) : (
													<Badge colorScheme={colorScheme} p={2}>
														{reper.status}
													</Badge>
												)}
											</Td>
											<Td color="gray.100" borderColor="gray.600">
												{showStatusComandaFurnizor ? (
													reper.statusComandaFurnizor === STATUS_REPER_FURNIZOR.failed ? (
														<Tooltip hasArrow label={`Motiv: ${reper.motivNereturnare}`}>
															<Badge colorScheme={colorSchemeComandaFurnizor} p={2}>
																{reper.statusComandaFurnizor}
															</Badge>
														</Tooltip>
													) : (
														<Badge colorScheme={colorSchemeComandaFurnizor} p={2}>
															{reper.statusComandaFurnizor}
														</Badge>
													)
												) : (
													<em>N/A</em>
												)}
											</Td>
											<Td borderColor="gray.600">
												<ReperActions reper={reper} solicitare={solicitare} />
											</Td>
										</Tr>
									);
								})}
							</Tbody>
						</Table>
					</TableContainer>
				</Flex>
			</Collapse>
			<Collapse in={isOpenAudit} unmountOnExit animateOpacity>
				<Flex
					flexDirection={'column'}
					p={4}
					border={'1px'}
					borderColor={'gray.700'}
					borderRadius={'md'}
					bg={'blueGray.900'}
				>
					{solicitare.audit.map((audit, index) => (
						<Flex key={index} alignItems={'flex-end'} gap={2}>
							<Text maxWidth={'50px'} fontFamily={'monospace'}>
								{index + 1}.
							</Text>
							<Text>{audit}</Text>
						</Flex>
					))}
				</Flex>
			</Collapse>
		</Box>
	);
};

const FilterInput = ({ filter, onChange, filterOptions }) => {
	switch (filter.filterType) {
		case 'select':
			const options = Array.from(filterOptions.get(filter.name) || []).map(option => ({
				label: option,
				value: option,
			}));

			return (
				<Select
					isMulti
					value={filter.value}
					onChange={value => onChange(value, filter.name)}
					options={options}
					isClearable
					styles={{
						option: baseStyles => ({
							...baseStyles,
							color: 'black',
						}),
					}}
				/>
			);
		case 'text':
			return (
				<Input
					placeholder={filter.placeholder}
					value={filter.value}
					onChange={e => onChange(e.target.value, filter.name)}
				/>
			);
		default:
			return null;
	}
};

const FiltreSection = ({ filters, filterOptions, isFetching, onChange, onResetFilters, onRefreshList, onSearch }) => {
	const [checked, setChecked] = useState(false);
	const canShowFinalizate = filters.some(
		filter => (filter.name === 'devizId' || filter.name === 'nrAuto') && filter.value
	);

	return (
		<Flex flexDirection={'column'} border={'1px'} borderColor={'gray.700'} borderRadius={'md'} p={4} gap={4}>
			<Flex flexDirection={'row'} gap={4} flexWrap={'wrap'}>
				{filters.map(filter => {
					return (
						<Flex key={filter.name} flexDirection={'column'} flex={'1 1 23%'} minW={'200px'} gap={1}>
							<Text color={'gray.400'} fontFamily={'monospace'} casing={'uppercase'}>
								{filter.label}:
							</Text>
							<FilterInput filter={filter} onChange={onChange} filterOptions={filterOptions} />
						</Flex>
					);
				})}
			</Flex>

			<Flex gap={2} justifyContent={'space-between'} alignItems={'center'}>
				<Flex gap={2}>
					<Button alignSelf={'flex-start'} variant={'outline'} onClick={onResetFilters}>
						Reseteaza filtre
					</Button>
					<Tooltip hasArrow label="Refresh lista">
						<IconButton
							icon={<IoMdRefresh />}
							variant={'outline'}
							onClick={onRefreshList}
							aria-label="Refresh lista"
							isLoading={isFetching}
						/>
					</Tooltip>
					{isFetching && (
						<Text color={'gray.400'} fontSize={'sm'} fontStyle={'italic'}>
							Refreshing data...
						</Text>
					)}
				</Flex>

				<Flex gap={4} alignItems={'center'}>
					<Tooltip
						hasArrow
						shouldWrapChildren
						label={
							<Text>
								Crt deviz <em>sau</em> nr. auto sunt obligatorii pentru a putea folosi acest checkbox.
							</Text>
						}
					>
						<Checkbox
							isChecked={checked}
							onChange={e => setChecked(e.target.checked)}
							isDisabled={!canShowFinalizate}
						>
							Include solicitari finalizate
						</Checkbox>
					</Tooltip>
					<Button onClick={() => onSearch(checked && canShowFinalizate)}>Cauta</Button>
				</Flex>
			</Flex>
		</Flex>
	);
};

const actions = Object.freeze({
	aproba: { titlu: 'Aproba retur', textConfirmare: 'Confirmi aprobarea returului?' },
	respinge: { titlu: 'Respinge retur', textConfirmare: 'Confirmi respingerea returului?' },
	returneaza: { titlu: 'Returneaza la furnizor', textConfirmare: 'Confirmi returnarea la furnizor?' },
	nereturneaza: { titlu: 'Ramane in stoc', textConfirmare: 'Confirmi ca nu poti returna reperul la furnizor?' },
	default: { titlu: 'N/A', textConfirmare: 'N/A' },
});

const ReperActions = ({ reper, solicitare }) => {
	const toast = useToast();
	const queryClient = useQueryClient();
	const devizId = solicitare.devizId;
	const solicitareId = solicitare.id;
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [action, setAction] = useState(actions.default);
	const [approvedQty, setApprovedQty] = useState(reper.qtySolicitata);
	const [motivRespingere, setMotivRespingere] = useState('');
	const [motivNereturnare, setMotivNereturnare] = useState('');
	const [crtAviz, setCrtAviz] = useState('');

	const { mutate: respingeReturPiesa, isLoading: isRespingeReturPiesaLoading } = useRespingeReturPiesa();
	const { mutate: aprobaReturPiesa, isLoading: isAprobaReturPiesaLoading } = useAprobaReturPiesa();
	const { mutate: returneazaLaFurnizor, isLoading: isReturneazaLaFurnizorLoading } = useReturneazaLaFurnizor();
	const { mutate: intoarceInDepozit, isLoading: isIntoarceInDepozitLoading } = useIntoarceInDepozit();

	const isLoading =
		isRespingeReturPiesaLoading ||
		isAprobaReturPiesaLoading ||
		isReturneazaLaFurnizorLoading ||
		isIntoarceInDepozitLoading;

	const handleAction = () => {
		if (action === actions.aproba) {
			aprobaReturPiesa(
				{
					devizId,
					solicitareId,
					reperId: reper.reperId,
					reperName: reper.reperName,
					docId: reper.docId,
					qtyAprobata: approvedQty,
				},
				{
					onSuccess: () => {
						queryClient.invalidateQueries(['solicitari-retur-piese']);
						onClose();
						toast({
							description: 'Returul a fost aprobat cu succes',
							status: 'success',
						});
					},
					onError: error => {
						toast({
							title: 'A aparut o eroare!',
							description: error.message,
							status: 'error',
						});
					},
				}
			);
		} else if (action === actions.respinge) {
			respingeReturPiesa(
				{
					devizId,
					solicitareId,
					reperId: reper.reperId,
					reperName: reper.reperName,
					docId: reper.docId,
					motivRespingere,
				},
				{
					onSuccess: () => {
						queryClient.invalidateQueries(['solicitari-retur-piese']);
						onClose();
						toast({
							description: 'Returul a fost respins cu succes',
							status: 'success',
						});
					},
					onError: error => {
						toast({
							title: 'A aparut o eroare!',
							description: error.message,
							status: 'error',
						});
					},
				}
			);
		} else if (action === actions.returneaza) {
			returneazaLaFurnizor(
				{
					devizId,
					solicitareId,
					reperId: reper.reperId,
					reperName: reper.reperName,
					docId: reper.docId,
					crtAviz,
				},
				{
					onSuccess: () => {
						queryClient.invalidateQueries(['solicitari-retur-piese']);
						onClose();
						toast({
							description: 'Reperul a fost returnat la furnizor.',
							status: 'success',
						});
					},
					onError: error => {
						toast({
							title: 'A aparut o eroare!',
							description: error.message,
							status: 'error',
						});
					},
				}
			);
		} else {
			intoarceInDepozit(
				{
					devizId,
					solicitareId,
					reperId: reper.reperId,
					reperName: reper.reperName,
					docId: reper.docId,
					motivNereturnare,
				},
				{
					onSuccess: () => {
						queryClient.invalidateQueries(['solicitari-retur-piese']);
						onClose();
						toast({
							description: 'Reper ramas in stoc.',
							status: 'success',
						});
					},
					onError: error => {
						toast({
							title: 'A aparut o eroare!',
							description: error.message,
							status: 'error',
						});
					},
				}
			);
		}
	};

	if (
		solicitare.status === STATUS_SOLICITARE_RETUR.finalizata ||
		(reper.status === STATUS_REPER_DE_RETURNAT && !reper.areComandaFurnizor)
	) {
		return <em>N/A</em>;
	}

	return (
		<>
			<Stack direction="column" spacing={2} maxWidth={'210px'}>
				{[STATUS_REPER_DE_RETURNAT.necesitaAprobare, STATUS_REPER_DE_RETURNAT.respins].includes(reper.status) && (
					<Button
						size="md"
						colorScheme="green"
						variant={'outline'}
						leftIcon={<IoMdCheckmark />}
						onClick={() => {
							setAction(actions.aproba);
							onOpen();
						}}
					>
						{actions.aproba.titlu}
					</Button>
				)}
				{[
					STATUS_REPER_DE_RETURNAT.necesitaAprobare,
					STATUS_REPER_DE_RETURNAT.deReturnat,
					STATUS_REPER_DE_RETURNAT.aprobat,
				].includes(reper.status) && (
					<Button
						size="md"
						variant={'outline'}
						colorScheme="red"
						leftIcon={<IoMdClose />}
						onClick={() => {
							setAction(actions.respinge);
							onOpen();
						}}
					>
						{actions.respinge.titlu}
					</Button>
				)}
				{reper.status === STATUS_REPER_DE_RETURNAT.returnat &&
					reper.statusComandaFurnizor === STATUS_REPER_FURNIZOR.deReturnat && (
						<Button
							size="md"
							colorScheme="green"
							variant={'outline'}
							leftIcon={<IoMdCheckmark />}
							onClick={() => {
								setAction(actions.returneaza);
								onOpen();
							}}
						>
							{actions.returneaza.titlu}
						</Button>
					)}
				{reper.status === STATUS_REPER_DE_RETURNAT.returnat &&
					reper.statusComandaFurnizor === STATUS_REPER_FURNIZOR.deReturnat && (
						<Button
							size="md"
							variant={'outline'}
							colorScheme="red"
							leftIcon={<IoMdClose />}
							onClick={() => {
								setAction(actions.nereturneaza);
								onOpen();
							}}
						>
							{actions.nereturneaza.titlu}
						</Button>
					)}
			</Stack>

			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent bg="gray.800">
					<ModalHeader color="gray.100">{action.titlu}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						{action.titlu === actions.aproba.titlu ? (
							<FormControl>
								<FormLabel color="gray.100">Cantitate aprobată</FormLabel>
								<NumberInput value={approvedQty} min={1} onChange={(_, num) => setApprovedQty(num)}>
									<NumberInputField />
									<NumberInputStepper>
										<NumberIncrementStepper />
										<NumberDecrementStepper />
									</NumberInputStepper>
								</NumberInput>
								<Text fontSize="sm" color="gray.400" mt={1}>
									Cantitate solicitată: {reper.qtySolicitata}
								</Text>
							</FormControl>
						) : action.titlu === actions.respinge.titlu ? (
							<FormControl>
								<FormLabel color="gray.100">Motiv respingere</FormLabel>
								<Textarea
									value={motivRespingere}
									onChange={e => setMotivRespingere(e.target.value)}
									placeholder="Completeaza motivul respingerii"
									bg="gray.700"
									color="gray.100"
									borderColor="gray.600"
									_hover={{ borderColor: 'gray.500' }}
									_focus={{ borderColor: 'blue.300' }}
								/>
							</FormControl>
						) : action.titlu === actions.returneaza.titlu ? (
							<FormControl>
								<FormLabel color="gray.100">Crt aviz retur</FormLabel>
								<NumberInput value={crtAviz} min={1} onChange={(_, num) => setCrtAviz(num)}>
									<NumberInputField />
								</NumberInput>
							</FormControl>
						) : (
							<FormControl>
								<FormLabel color="gray.100">De ce nu poate fi returnat?</FormLabel>
								<Textarea
									value={motivNereturnare}
									onChange={e => setMotivNereturnare(e.target.value)}
									placeholder="Scrie motivul"
									bg="gray.700"
									color="gray.100"
									borderColor="gray.600"
									_hover={{ borderColor: 'gray.500' }}
									_focus={{ borderColor: 'blue.300' }}
								/>
							</FormControl>
						)}
						<Text color="gray.100" mt={4}>
							{action.textConfirmare}
						</Text>
					</ModalBody>

					<ModalFooter>
						<Button variant="ghost" mr={3} onClick={onClose}>
							Anuleaza
						</Button>
						<Button onClick={() => handleAction()} isLoading={isLoading}>
							{action.titlu}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};

function SolicitareInfoTitle({ children }) {
	return (
		<Text
			fontSize={'small'}
			fontFamily={'monospace'}
			color={'gray.400'}
			fontWeight={'bold'}
			textTransform={'uppercase'}
		>
			{children}
		</Text>
	);
}

function getInitialFilterValues(filtersStructure) {
	return new Map(filtersStructure.map(filter => [filter.name, filter]));
}

function buildFilterOptions(data, filterKeys) {
	const options = new Map();
	if (!data) return options;

	for (const item of data) {
		for (const key of filterKeys) {
			const value = item[key];

			if (!options.has(key)) {
				options.set(key, new Set([value]));
			}

			options.get(key).add(value);
		}
	}
	// console.log('in buildFilterOptions', { data, filterKeys, options });

	return options;
}
